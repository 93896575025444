import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="relative container mx-auto px-6 py-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800">ติดต่อเรา</h2>
        <p className="text-gray-600 mt-4">หากคุณมีคำถามหรือต้องการข้อมูลเพิ่มเติม โปรดติดต่อเราได้ที่นี่</p>
      </div>
      
      {/* Overlay Message */}
      <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center z-10">
        <p className="text-2xl font-bold text-red-600">จะเปิดให้ลงทะเบียนเร็วๆ นี้</p>
      </div>

      {/* Form Section */}
      <form className="relative max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg z-0">
        <div className="mb-4">
          <label className="block text-gray-700">ชื่อ</label>
          <input type="text" className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="กรอกชื่อของคุณ" required />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">อีเมล</label>
          <input type="email" className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600" placeholder="กรอกอีเมลของคุณ" required />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">ข้อความ</label>
          <textarea className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600" rows="5" placeholder="กรอกข้อความของคุณ" required></textarea>
        </div>
        <button type="submit" className="w-full bg-indigo-600 text-white p-3 rounded-md hover:bg-indigo-700 transition">ส่งข้อความ</button>
      </form>
    </section>
  );
};

export default Contact;
