// src/components/Hero.js
import React from 'react';

const Hero = () => {
  return (
    <section className="bg-white mt-16">
      <div className="container mx-auto px-6 py-20 text-center index">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">ระบบขายสินค้าใน LINE ที่เชื่อมต่อกับร้านค้าของคุณ</h1>
        <p className="text-gray-600 mb-6">จัดการการขายสินค้าผ่าน LINE ได้ง่ายและสะดวก รวดเร็ว พร้อมการเชื่อมต่อกับร้านค้าของคุณที่ muw.asia</p>
        <a href="https://dash.muw.asia/" className="bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700 transition"><i class="fa-solid fa-arrow-right-to-bracket"></i> GO TO Dashboard</a>
      </div>
    </section>
  );
};

export default Hero;
