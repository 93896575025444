// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white shadow">
      <div className="container mx-auto px-6 py-4 flex flex-col md:flex-row justify-between items-center">
        <p className="text-gray-600">&copy; 2024 muw.asia X Hnawny Cloud สงวนลิขสิทธิ์.</p>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="#" className="text-gray-600 hover:text-indigo-600 transition"><i className="fab fa-facebook-f"></i></a>
          <a href="#" className="text-gray-600 hover:text-indigo-600 transition"><i className="fab fa-twitter"></i></a>
          <a href="#" className="text-gray-600 hover:text-indigo-600 transition"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
