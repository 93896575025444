// src/components/Pricing.js
import React from 'react';

const Pricing = () => {
  return (
    <section id="pricing" className="bg-white py-20">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">ราคาแพ็กเกจ</h2>
          <p className="text-gray-600 mt-4">เลือกแพ็กเกจที่เหมาะสมกับธุรกิจของคุณ</p>
        </div>
        <div className="flex flex-wrap justify-center">
          {/* แพ็กเกจถาวร */}
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="border rounded-lg shadow-lg p-6 text-center">
              <h3 className="text-2xl font-semibold text-indigo-600 mb-4">แพ็กเกจถาวร</h3>
              <p className="text-gray-700 text-4xl font-bold">฿15,000</p>
              <ul className="text-left mt-6 mb-6">
                <li className="mb-2 font-bold text-green-600">ใช้งานตลอดชีพ <span className="text-xs text-gray-500">ไม่มีวันหมดอายุ</span></li>
                <li className="mb-2 font-bold text-green-600">การสนับสนุนตลอดการใช้งาน <span className="text-xs text-gray-500">(24/7)</span></li>
                <li className="mb-2 font-bold text-green-600">ฟีเจอร์ครบครัน <span className="text-xs text-gray-500">มีอัพเดทฟีเจอร์ต่างๆ อัตโนมัติ</span></li>
                <li className="mb-2 font-bold text-green-600">สามารถ Customize ตามต้องการ <span className="text-xs text-gray-500">แต่งในแบบของคุณ</span></li>
                <li className="mb-2 font-bold text-green-600">ได้ Webhook เป็นของตัวเอง <span className="text-xs text-gray-500">มี xx.muw.asia เป็นของตัวเองฟรี</span></li>
              </ul>
              <a href="#contact" className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition">ติดต่อเรา</a>
            </div>
          </div>
          {/* แพ็กเกจรายเดือน */}
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="border rounded-lg shadow-lg p-6 text-center">
              <h3 className="text-2xl font-semibold text-indigo-600 mb-4">แพ็กเกจรายเดือน (แนะนำ)</h3>
              <p className="text-gray-700 text-4xl font-bold">฿1,200<span className="text-base"> / เดือน</span></p>
              <ul className="text-left mt-6 mb-6">
                <li className="mb-2 font-bold text-green-600">การสนับสนุนตลอดการใช้งาน <span className="text-xs text-gray-500">(24/7)</span></li>
                <li className="mb-2 font-bold text-green-600">อัพเดตฟีเจอร์ใหม่ๆ <span className="text-xs text-gray-500">(อัตโนมัติ)</span></li>
                <li className="mb-2 font-bold text-green-600">เข้าถึงข้อมูลรายงาน <span className="text-xs text-gray-500">(ขั้นสูง)</span></li>
                <li className="mb-2 font-bold text-green-600">เข้าถึงข้อมูลแบบไม่จำกัด <span className="text-xs text-gray-500">เพิ่มสินค้า / เก็บข้อมูลลูกค้า ได้ไม่จำกัด</span></li>
                <li className="mb-2 font-bold text-green-600">เข้าถึงระบบทดสอบก่อนใคร <span className="text-xs text-gray-500">(Beta Tester)</span></li>
              </ul>
              <a href="#contact" className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition">GO TO Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
