// src/components/Features.js
import React from 'react';

const Features = () => {
  return (
    <section id="features" className="container mx-auto px-6 py-20">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800">คุณสมบัติเด่น</h2>
        <p className="text-gray-600 mt-4">ระบบของเรามีฟีเจอร์ครบครันเพื่อการขายที่มีประสิทธิภาพ</p>
      </div>
      <div className="flex flex-wrap">
        {/* ฟีเจอร์ที่ 1 */}
        <div className="w-full md:w-1/3 text-center mb-8">
          <div className="mx-auto mb-4">
            <svg className="w-12 h-12 text-indigo-600 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {/* ใส่ไอคอนที่นี่ */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">การเชื่อมต่อ LINE</h3>
          <p className="text-gray-600 mt-2">จัดการการขายสินค้าผ่าน LINE ได้อย่างมีประสิทธิภาพและง่ายดาย</p>
        </div>
        {/* ฟีเจอร์ที่ 2 */}
        <div className="w-full md:w-1/3 text-center mb-8">
          <div className="mx-auto mb-4">
            <svg className="w-12 h-12 text-indigo-600 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {/* ใส่ไอคอนที่นี่ */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5V4H2v16h5m10-10h4m-4 4h4"/>
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">การเชื่อมต่อร้านค้า</h3>
          <p className="text-gray-600 mt-2">เชื่อมต่อกับร้านค้าของคุณที่ muw.asia ได้ง่ายๆ ในไม่กี่ขั้นตอน</p>
        </div>
        {/* ฟีเจอร์ที่ 3 */}
        <div className="w-full md:w-1/3 text-center mb-8">
          <div className="mx-auto mb-4">
            <svg className="w-12 h-12 text-indigo-600 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {/* ใส่ไอคอนที่นี่ */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-6a2 2 0 012-2h2a2 2 0 012 2v6"/>
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">รายงานและวิเคราะห์</h3>
          <p className="text-gray-600 mt-2">ดูข้อมูลการขายและวิเคราะห์เพื่อปรับปรุงธุรกิจของคุณ</p>
        </div>
      </div>
    </section>
  );
};

export default Features;
