import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* โลโก้ */}
        <a href="#" className="flex items-center text-xl font-bold text-indigo-600">
          <svg className="w-8 h-8 mr-2" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10
                     10-4.48 10-10S17.52 2 12 2zm1 17.93c-2.83.48-5.69-.21-7.81-1.93
                     1.22-.76 2.13-1.91 2.65-3.27.52-1.36.65-2.87.37-4.34C5.07 12.35
                     5 11.2 5 10c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.2-.07 2.35-.37
                     3.66-.28 1.47-.15 2.98.37 4.34 1.22 1.36 2.13 2.51 2.65 3.27-2.12
                     1.72-4.98 2.41-7.81 1.93z"/>
          </svg>

          muw.asia
        </a>
        
        {/* เมนูนำทาง (Desktop) */}
        <nav className="hidden md:flex space-x-6">
          <a href="#features" className="text-gray-700 px-1 py-2 hover:text-indigo-600 transition duration-300">คุณสมบัติ</a>
          <a href="#pricing" className="text-gray-700 px-1 py-2 hover:text-indigo-600 transition duration-300">ราคาแพ็กเกจ</a>
          <a href="#partners" className="text-gray-700 px-1 py-2 hover:text-indigo-600 transition duration-300">ร้านค้าพันธมิตร</a>
          <a href="https://dash.muw.asia/" className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition"><i class="fa-solid fa-arrow-right-to-bracket"></i> GO TO Dashboard</a>
        </nav>
                
        {/* ปุ่มเมนู (Mobile) */}
        <div className="md:hidden flex items-center">
          <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {!isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              )}
            </svg>
          </button>
        </div>
      </div>
      
      {/* เมนูนำทาง (Mobile) */}
      <div
        className={`md:hidden overflow-hidden bg-white shadow transition-max-height duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0'}`}>
        <a href="#features" className="block text-gray-700 hover:text-indigo-600 px-3 py-2 rounded-md text-base font-medium">คุณสมบัติ</a>
        <a href="#pricing" className="block text-gray-700 hover:text-indigo-600 px-3 py-2 rounded-md text-base font-medium">ราคาแพ็กเกจ</a>
        <a href="#partners" className="block text-gray-700 hover:text-indigo-600 px-3 py-2 rounded-md text-base font-medium">ร้านค้าพันธมิตร</a>
        <a href="https://dash.muw.asia/" className="block text-white bg-indigo-600 px-2 py-2 mx-2 mb-2 rounded-md text-base font-medium hover:bg-indigo-700 transition duration-300"><i class="fa-solid fa-arrow-right-to-bracket"></i> GO TO Dashboard</a>
      </div>
    </header>
  );
};

export default Navbar;
