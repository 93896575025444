// src/components/Partners.js
import React, { useEffect } from 'react';

const Partners = () => {

  useEffect(() => {
    const carousel = document.getElementById('partners-carousel');

    let scrollAmount = 0;
    const scrollStep = 1;
    const scrollInterval = 20;

    const autoScroll = () => {
      if (scrollAmount >= carousel.scrollWidth / 2) {
        scrollAmount = 0;
        carousel.scrollLeft = 0;
      } else {
        carousel.scrollLeft += scrollStep;
        scrollAmount += scrollStep;
      }
    }

    const interval = setInterval(autoScroll, scrollInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="partners" className="bg-gray-100 py-20">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">ร้านค้าพันธมิตร</h2>
          <p className="text-gray-600 mt-4">ร่วมเป็นส่วนหนึ่งกับร้านค้าต่างๆ ที่เชื่อถือเรา</p>
        </div>
        {/* Carousel */}
        <div className="flex overflow-x-auto space-x-6 py-4" id="partners-carousel">
          {/* เพิ่มรูปภาพร้านค้าพันธมิตรที่นี่ */}
          <div className="flex-shrink-0 text-center">
            <img src="/img/partners/LOGO4a44ec01b8d66d1a.jpeg" alt="Supportotp24hour Ltd." title='Supportotp24hour' className="w-40 h-40 object-cover rounded-md" />
            <p className="mt-2 text-gray-700">Supportotp24hour Ltd.</p>
          </div>
          <div className="flex-shrink-0 text-center">
            <img src="/img/partners/logo (1).png" alt="Peamsub24hr" title='Peamsub24hr' className="w-40 h-40 object-cover rounded-md" />
            <p className="mt-2 text-gray-700">Peamsub24hr</p>
          </div>
          <div className="flex-shrink-0 text-center">
            <img src="/img/partners/TermgameSI.jpeg" alt="ร้านค้า 3" className="w-40 h-40 object-cover rounded-md" />
            <p className="mt-2 text-gray-700">Termgame SI</p>
          </div>
          {/* <div className="flex-shrink-0 text-center">
            <img src="https://via.placeholder.com/150" alt="ร้านค้า 4" className="w-40 h-40 object-cover rounded-md" />
            <p className="mt-2 text-gray-700">ร้านค้า 4</p>
          </div>
          <div className="flex-shrink-0 text-center">
            <img src="https://via.placeholder.com/150" alt="ร้านค้า 5" className="w-40 h-40 object-cover rounded-md" />
            <p className="mt-2 text-gray-700">ร้านค้า 5</p>
          </div> */}
          {/* คุณสามารถเพิ่มรูปเพิ่มเติมได้ตามต้องการ */}
        </div>
      </div>
    </section>
  );
};

export default Partners;
